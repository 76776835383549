import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Breadcrumbs, InputField, Modal, SearchBox } from "../../components";
import { toast } from "react-toastify";
import {
  CheckBadgeIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/solid";
import EditGuide from "../../components/EditGuide";
import MarkdownConverter from "./MarkdownConverter";
import { Link, useParams } from "react-router-dom";
import { websites } from "../../components/Menu/Menu";
import LayoutHead from "../Layout/LayoutHead";
import { Bars3BottomLeftIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import FileUploadForm from "../../components/FileUploadForm";
import SectionsList from "../Layout/SectionsList";

export default function Blogs({ show_navlabel, toggleNavbar }) {
  const { website } = useParams();
  const site = websites.find((site) => site.domain === website);
  const [newKey, setNewKey] = useState("");
  const [newValue, setNewValue] = useState("");
  const [blogsList, setBlogsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [modalType, setModalType] = useState("");
  const [currentBlog, setCurrentBlog] = useState(null);

  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const getBlog = (blog) => {
    setLoading(true);
    setNewKey(blog.title);
    setNewValue("");
    axios
      .get(
        `https://api15.ecommcube.com/_${
          site.id
        }apidata/tagtext?tag=blog_${blog.title
          .replaceAll(" ", "_")
          .toLowerCase()}&pass=billy`
      )
      .then((res) => setNewValue(res?.data?.text))
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handleNewBlog = () => {
    setNewKey("");
    setNewValue("");
    setModalType("newBlog");
    handleModal();
  };
  const handleUpdateBlog = (blog) => {
    setLoading(true);
    setCurrentBlog(blog); // Set the current blog being edited
    setNewKey(blog.title);
    setNewValue("");
    setModalType("updateBlog");
    setKeyToDelete(`blog_${blog.title.replaceAll(" ", "_").toLowerCase()}`);
    getBlog(blog);
    handleModal();
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const deleteBlog = (index, blog) => {
    setDeleteConfirmation(index);
    setKeyToDelete(`blog_${blog.title.replaceAll(" ", "_").toLowerCase()}`);
  };

  const handleConfirmDelete = (index) => {
    const updatedBlogsList = [...blogsList];
    updatedBlogsList.splice(index, 1);
    setBlogsList(updatedBlogsList);
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/jtags_delete?${keyToDelete}&pass=billy`
      )
      .then(() => toast.success("Blog Deleted"))
      .catch((error) => {
        console.error(error); // Handle errors as needed
        setKeyToDelete("");
      });
    setDeleteConfirmation(null);
    if (index !== -1) {
      try {
        axios.post(
          `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
          null,
          {
            params: {
              blog_list: JSON.stringify(updatedBlogsList), // Convert the array to JSON string
              pass: "billy",
            },
          }
        );

        // Update the state with the new blogs list
        setBlogsList(updatedBlogsList);
        toast.success("Blog Removed From List");
      } catch (err) {
        toast.error(err);
      }
    }
  };

  const getBLogsList = () => {
    axios
      .get(
        `https://api15.ecommcube.com/_${site.id}apidata/tagtext?tag=blog_list&pass=billy`
      )
      .then((res) => setBlogsList(JSON.parse(res.data.text)))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBLogsList();
  }, []);

  // Function to adjust the blogsList array
  const adjustBlogsList = (blogsList) => {
    const adjustedList = blogsList.map((blog) => ({
      title: blog.title.trim(),
      description: blog.description.replace(/\n/g, " ").trim(),
      created_at: blog.created_at,
    }));
    return adjustedList;
  };

  // Modify your SubmitBlog function to use this adjustment function
  const SubmitBlog = async (event) => {
    event.preventDefault();

    // Create the new object with truncated newValue
    const newObject = {
      title: newKey,
      description: newValue.slice(0, 200),
      created_at: new Date().toISOString(), // Add the current time in ISO format
    };

    // Add the new object to the blogsList state
    const updatedBlogsList = [...blogsList, newObject];
    setBlogsList(updatedBlogsList);

    // Adjust the updatedBlogsList
    const adjustedBlogsList = adjustBlogsList(updatedBlogsList);

    // Form the formatted key
    const formatedKey = "blog_" + newKey.toLowerCase().replaceAll(" ", "_");

    try {
      // Make an API call to update the key-value pair on the server
      await axios.post(
        `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
        null,
        {
          params: {
            [formatedKey]: newValue,
            blog_list: JSON.stringify(adjustedBlogsList), // Convert the array to JSON string
            pass: "billy",
          },
        }
      );

      toast.success("New Data Added");
      handleModal();
    } catch (err) {
      toast.error(err);
    }
  };

  const UpdateBlogsList = async (event) => {
    event.preventDefault();
    const updatedBlogsList = [...blogsList]; // Create a copy of the current blogs list

    // Find the blog to edit by its title in the updatedBlogsList array
    const indexToUpdate = updatedBlogsList.findIndex(
      (blog) => blog.title === currentBlog.title
    );

    if (indexToUpdate !== -1) {
      // Check if the blog was found
      // Update the title and description of the found blog
      updatedBlogsList[indexToUpdate].title = newKey;
      updatedBlogsList[indexToUpdate].description = newValue.slice(0, 200);

      // Add or update the created_at and updated_at fields with the current time
      const currentTime = new Date().toISOString();
      updatedBlogsList[indexToUpdate].created_at =
        updatedBlogsList[indexToUpdate].created_at || currentTime;
      updatedBlogsList[indexToUpdate].updated_at = currentTime;

      const adjustedBlogsList = adjustBlogsList(updatedBlogsList);

      try {
        // Update the blog and blogs list array on the server
        const formatedKey = "blog_" + newKey.toLowerCase().replaceAll(" ", "_");
        await axios.post(
          `https://api15.ecommcube.com/_${site.id}apidata/jtags_update`,
          null,
          {
            params: {
              [formatedKey]: newValue,
              blog_list: JSON.stringify(adjustedBlogsList), // Convert the array to JSON string
              pass: "billy",
            },
          }
        );

        // Update the state with the new blogs list
        setBlogsList(updatedBlogsList);

        if (keyToDelete !== formatedKey) {
          axios
            .get(
              `https://api15.ecommcube.com/_${site.id}apidata/jtags_delete?${keyToDelete}&pass=billy`
            )
            .then(() => console.log("Old Tag Deleted"))
            .catch((error) => {
              console.error(error); // Handle errors as needed
              setKeyToDelete("");
            });
        }

        toast.success("Blog Updated");
        handleModal();
      } catch (err) {
        toast.error(err);
      }
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const copyBlogName = (blogName) => {
    navigator.clipboard
      .writeText(blogName)
      .then(() => {
        toast.success("blogName copied", { autoClose: 1000 });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <LayoutHead website={site}>
        <div className="flex items-center gap-3">
          {!show_navlabel && (
            <>
              {" "}
              <button
                type="button"
                onClick={toggleNavbar}
                className="flex items-center justify-center w-fit"
              >
                <Bars3BottomLeftIcon className="cursor-pointer w-6" />
              </button>
              <Link to="/" className="ml-3 text-lg font-medium">
                Dashboard
              </Link>
            </>
          )}

          <div
            className={`flex items-center gap-3 ${
              !show_navlabel && "flex-row-reverse"
            }`}
          >
            <a
              target="_blank"
              href={`https://www.${site?.domain}`}
              className="text-xl font-bold capitalize text-gray-700 whitespace-nowrap flex items-center gap-1"
              rel="noreferrer"
            >
              <GlobeAltIcon className="text-gray-700 w-6 mt-1" />
              {site.name}
            </a>
          </div>
        </div>
      </LayoutHead>

      <div className="p-6">
        <div className="flex items-center justify-between">
          <SearchBox
            className="bg-white shadow-sm"
            placeholder="Search Blog"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <h1 className="elementHeading2">Blogs {site.domain}</h1>
          <div className="flex items-center justify-end gap-2">
            <button onClick={handleNewBlog} className="btnPrimary shadow-sm">
              New Blog
            </button>
          </div>
        </div>
        <div className="bg-white rounded-md p-6 mt-5">
          <div className="h-[calc(100vh-280px)] overflow-y-scroll">
            {loading ? (
              <div className="flex items-center justify-center py-10 w-full">
                <img src="/loading.gif" className="w-16" alt="" />
              </div>
            ) : (
              <div className="grid lg:grid-cols-2 gap-10 gap-y-5">
                {blogsList
                  .filter((blog) =>
                    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
                  )
                  .map((blog, index) => (
                    <div
                      key={index}
                      className="w-full grid lg:grid-cols-blog gap-3 mb-5"
                    >
                      <div
                        title={blog.title}
                        className="h-32 overflow-hidden rounded-xl"
                      >
                        <img
                          title={blog.title}
                          className="h-full w-full hover:scale-110 transition-all"
                          src={`https://api15.ecommcube.com/${
                            site.domain
                          }/blogs/${blog.title
                            .replaceAll(" ", "-")
                            .toLowerCase()}.webp`}
                          alt="Blog"
                        />
                      </div>
                      <div>
                        <h4 className="font-bold capitalize">
                          {blog.title.length > 40
                            ? `${blog.title.slice(0, 40)}...`
                            : blog.title}
                        </h4>
                        <div className="flex items-center text-gray-500 text-sm mb-1 gap-3">
                          <p>Created at: {formatDate(blog.created_at)}</p>
                          <p>Last Updated: {formatDate(blog.updated_at)}</p>
                        </div>
                        <p className="text-gray-700 text-sm">
                          {blog?.description.slice(0, 110)}...
                        </p>
                        <div className="flex items-center text-sm gap-2 mt-2">
                          <a
                            className="btnPrimary py-1 px-3"
                            target="_blank"
                            href={`https://${site.domain}/blog/${blog.title
                              .replaceAll(" ", "-")
                              .toLowerCase()}`}
                            rel="noreferrer"
                          >
                            View On Website
                          </a>
                          <button
                            onClick={() => handleUpdateBlog(blog)}
                            className="btnPrimary py-1 px-3 bg-black "
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => deleteBlog(index, blog)} // Pass the index
                            className="btnPrimary py-1 px-3 bg-red-500"
                          >
                            Delete
                          </button>
                          <div className="relative">
                            {deleteConfirmation === index && ( // Check if the index matches
                              <div className="p-5 rounded-lg bg-white absolute top-0 right-0 shadow-lg shadow-black/30 w-fit">
                                <p className="font-medium text-base">
                                  Are you sure you want to delete {keyToDelete}?
                                </p>
                                <div className="flex items-center justify-end gap-4 mt-3">
                                  <button
                                    onClick={() => setDeleteConfirmation(null)}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    onClick={() => handleConfirmDelete(index)}
                                    className="btnPrimary px-3 bg-red-500"
                                  >
                                    Delete Blog
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {(modalType === "newBlog" || modalType === "updateBlog") && (
            <Modal
              open={open}
              handleModal={handleModal}
              cancelButtonRef={cancelButtonRef}
              disabled={newKey === "" || newValue === ""}
              handleModalSubmit={
                (modalType === "newBlog" && SubmitBlog) ||
                (modalType === "updateBlog" && UpdateBlogsList)
              }
              className="max-w-screen-xl"
            >
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-bold flex items-center">
                  {modalType === "newBlog" ? "New Blog" : "Update Blog"}{" "}
                  <span className="flex items-center gap-1 ml-2 text-blue-500 font-medium">
                    (Markdown) <CheckBadgeIcon className="w-5 " />
                  </span>
                </h2>
                <div className="flex items-center gap-3 justify-center">
                  <div className="h-[2px] w-12 bg-secondary"></div>
                  <p className="text-lg font-medium mb-1 text-secondary">
                    {site?.domain}
                  </p>
                  <div className="h-[2px] w-12 bg-secondary"></div>
                </div>
                <div className="flex items-center justify-end gap-3">
                  <EditGuide />
                  <button
                    type="button"
                    className="btnPrimary text-sm font-medium bg-red-500"
                    onClick={handleModal}
                  >
                    Cancel
                  </button>
                  {loading ? (
                    <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
                      <img src="/loading.gif" className="w-7" alt="" />
                    </div>
                  ) : (
                    <button
                      onClick={
                        (modalType === "newBlog" && SubmitBlog) ||
                        (modalType === "updateBlog" && UpdateBlogsList)
                      }
                      title={
                        (newKey === "" || newValue === "") &&
                        "Please select a tag to update value"
                      }
                      disabled={newKey === "" || newValue === ""}
                      className="btnPrimary text-sm font-medium bg-green-600 disabled:cursor-not-allowed"
                    >
                      Update Blog
                    </button>
                  )}
                </div>
              </div>
              <FileUploadForm
                blogName={`${newKey.toLowerCase().replaceAll(" ", "-")}.webp`}
              />
              <div className="grid grid-cols-2 gap-7">
                <InputField
                  label="Blog Title"
                  placeholder="blog title here"
                  value={newKey}
                  onChange={(e) => setNewKey(e.target.value)}
                />
                <div className="flex items-center gap-6">
                  <InputField
                    label="Blog Tag"
                    className="flex-1"
                    placeholder="blog title here"
                    value={`blog_${newKey.toLowerCase().replaceAll(" ", "_")}`}
                    disabled
                  />
                  <div className="flex items-center flex-col gap-1">
                    <ClipboardDocumentIcon
                      title="copy blog name"
                      onClick={() =>
                        copyBlogName(newKey.toLowerCase().replaceAll(" ", "-"))
                      }
                      className="w-6 cursor-pointer hover:opacity-80 mt-5 text-gray-500"
                    />
                    <span className="text-xs font-medium">Copy Name</span>
                  </div>
                </div>
              </div>
              <MarkdownConverter
                loading={loading}
                newKey={newKey}
                newValue={newValue}
                setNewValue={setNewValue}
                title="Blog content"
                height="h-[calc(100vh-200px)]"
              />
            </Modal>
          )}
        </div>
      </div>
    </>
  );
}
