export const websites = [
  process.env.NODE_ENV === "development"
    ? { id: 0, name: "Localhost", domain: "localhost:3000" }
    : null,
  { id: 1, name: "towing near me", domain: "towingnearme.us", active: true },
  { id: 2, name: "handyman", domain: "handyman.us", active: true },
  {
    id: 3,
    name: "garage door repair",
    domain: "garagedoorrepair.us",
    active: true,
  },
  { id: 4, name: "chimney sweep", domain: "chimney-sweep.us", active: true },
  {
    id: 5,
    name: "carpet cleaning",
    domain: "carpetcleaning-nearme.us",
    active: true,
  },
  {
    id: 6,
    name: "locksmith near me",
    domain: "locksmith-near-me.us",
    active: true,
  },
  {
    id: 7,
    name: "dryervent cleaning",
    domain: "dryervent-cleaning.us",
    active: true,
  },
  {
    id: 8,
    name: "air duct cleaning",
    domain: "airductcleaning.us",
    active: true,
  },
  { id: 9, name: "gate repair", domain: "gaterepairnearme.us", active: true },
  {
    id: 10,
    name: "plumbers near me",
    domain: "plumbersnearme.us",
    active: true,
  },
  { id: 11, name: "movers nearme", domain: "movers-nearme.us", active: true },
  {
    id: 12,
    name: "appliance repair",
    domain: "appliance-repair.us",
    active: true,
  },
  {
    id: 13,
    name: "sliding door repair",
    domain: "slidingdoor-repair.us",
    active: true,
  },
  {
    id: 14,
    name: "pestcontrol",
    domain: "pestcontrol-nearme.us",
    active: true,
  },
  { id: 15, name: "roofing", domain: "roofing-nearme.us", active: true },
  {
    id: 16,
    name: "fire damage restoration",
    domain: "firedamage-restoration.com",
    active: true,
  },
  {
    id: 17,
    name: "water damage restoration",
    domain: "waterdamage-restoration.com",
    active: true,
  },
  {
    id: 18,
    name: "mold removal nearme",
    domain: "moldremoval-nearme.com",
    active: true,
  },
  {
    id: 19,
    name: "roadside tows",
    domain: "roadsidetows.com",
    active: true,
  },
  {
    id: 20,
    name: "allusa towing",
    domain: "allusatowing.com",
    active: true,
  },
  // {
  //   id: 20,
  //   name: "Localhost",
  //   domain: "localhost:3000",
  //   active: true,
  // },
].filter(Boolean);

export const tagsType = [
  { name: "##service##", des: "for opted service" },
  { name: "##state##", des: "for opted state" },
  { name: "##county##", des: "for opted county" },
  { name: "##city##", des: "for opted city" },
  { name: "##zip##", des: "for opted zip_name" },
  { name: "##phone##", des: "per area code" },
  { name: "##area##", des: "for immediate route" },
  { name: "##website##", des: "website domain name" },
  { name: "<<tagkey>>", des: "Refference key" },
];

export const vishnuIds = [
  { id: 1, name: "towing near me", domain: "towingnearme.us" },
  { id: 2, name: "carpet cleaning", domain: "carpetcleaning-nearme.us" },
  { id: 3, name: "chimney sweep", domain: "chimney-sweep.us" },
  { id: 4, name: "locksmith near me", domain: "locksmith-near-me.us" },
  { id: 5, name: "handyman", domain: "handyman.us" },
  { id: 6, name: "air duct cleaning", domain: "airductcleaning.us" },
  { id: 7, name: "gate repair", domain: "gaterepairnearme.us" },
  { id: 8, name: "garage door repair", domain: "garagedoorrepair.us" },
  { id: 9, name: "sliding door repair", domain: "slidingdoor-repair.us" },
  { id: 10, name: "dryervent cleaning", domain: "dryervent-cleaning.us" },
  { id: 11, name: "pestcontrol", domain: "pestcontrol-nearme.us" },
  { id: 12, name: "plumbers near me", domain: "plumbersnearme.us" },
  { id: 13, name: "roofing", domain: "roofing-nearme.us" },
  { id: 14, name: "movers nearme", domain: "movers-nearme.us" },
  { id: 15, name: "appliance repair", domain: "appliance-repair.us" },
  {
    id: 16,
    name: "water damage restoration",
    domain: "waterdamage-restoration.com",
  },
  {
    id: 17,
    name: "fire damage restoration",
    domain: "firedamage-restoration.com",
  },
  { id: 18, name: "mold removal nearme", domain: "moldremoval-nearme.com" },
];
